import { REACT_APP_BASE } from '../config'

function response(response) {
  return response
}

function error(error) {
  if (error.response && error.response.status === 401) {
    window.location.href = `${REACT_APP_BASE}/login` //?expired`
    return Promise.reject(error)
  }
  if (error.response && error.response.status === 409) {
    return Promise.resolve(error.response)
  }

  console.error('ERROR', error)
  return Promise.reject(error)
}

export { response, error }
