import { useState } from 'react'
import { getDataFromResponse, getSitePathParam } from '../helper/axios.client'
import { errorFlag } from '../helper/messages'
import i18n from 'i18next'
import { useApiClientWithInterceptor } from 'helper/axiosInterceptor'

function useQualitySummary(customerId = '', siteId = '') {
  const client = useApiClientWithInterceptor('quality/summary/')
  const [data, setData] = useState([])

  const getQualitySummary = async () => {
    const siteIdParam = getSitePathParam(siteId)
    return await client.get(`/${customerId}${siteIdParam}`, {
      params: { timestamp: Date.now() },
    })
  }

  const reload = async () => {
    try {
      const response = await getQualitySummary()
      const data = getDataFromResponse(response.data.data, 'quality_summary')
      setData(data)
    } catch (e) {
      if (e.response?.status !== 409) {
        console.error(e)
        errorFlag(i18n.t('Error fetching data'))
      }
    }
  }

  return { data, reload }
}

export default useQualitySummary
