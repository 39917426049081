import { calculateTrendingPercentage } from '../../../../helper/costs'

function CostsTrendingArrow({ amount = 0, baseAmount = 0 }) {
  const getCostsTrending = () => {
    const trendingPercentage = calculateTrendingPercentage(amount, baseAmount)

    if (trendingPercentage < 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span className="has-text-grey">{`${trendingPercentage} %`}</span>
          <span className="icon has-text-grey">
            <i className="fa fa-caret-down fa-lg" />
          </span>
        </div>
      )
    } else if (trendingPercentage > 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span className="has-text-grey">{`+${trendingPercentage} %`}</span>
          <span className="icon has-text-grey">
            <i className="fa fa-caret-up fa-lg" />
          </span>
        </div>
      )
    } else {
      return (
        <div>
          <span className="has-text-grey">{`${trendingPercentage} %`}</span>
        </div>
      )
    }
  }

  // don't render trending stats, if either amount is 0
  if (amount === 0 || baseAmount === 0) {
    return null
  }

  return <>{getCostsTrending()}</>
}

export default CostsTrendingArrow
