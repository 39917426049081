import React, { useEffect, useState } from 'react'
import {
  getAPIClient,
  getAuthClient,
  getDataFromResponse,
} from '../helper/axios.client'
import { OPTION_ALL, OPTION_EMPTY } from '../helper/constants'
import { errorFlag } from '../helper/messages'
import i18n from 'i18next'

export const AppContext = React.createContext()

export const AppContextProvider = ({ authContext, children }) => {
  const apiClient = getAPIClient('customers')
  const authClient = getAuthClient()
  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [sites, setSites] = useState([])
  const [selectedSiteId, setSelectedSiteId] = useState(OPTION_EMPTY)
  const [isFetchingData, setIsFetchingData] = useState(false)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (selectedCustomer) {
      setSites(selectedCustomer.sites)
      setSelectedSiteId(OPTION_ALL)
    }
  }, [selectedCustomer])

  const init = async () => {
    // On initial load: fetch user profile, then customers and sites

    setIsFetchingData(true)
    try {
      const result = await authClient.get('/profile', {
        params: { timestamp: Date.now() },
      })
      const user = result.data.user

      if (result.status === 200 && result.data) {
        authContext.setCurrentUser(user)
      }
      await updateCustomers()
    } catch (e) {
      console.error('ERROR', e)
    }
    setIsFetchingData(false)
  }

  const refreshProfileData = async () => {
    await init()
  }

  const fetchCustomers = async () => {
    const response = await apiClient.get('', {
      params: { timestamp: Date.now() },
    })
    if (response.status === 200 && response.data) {
      const data = getDataFromResponse(response.data.data, 'customers')
      setCustomers(data)
      const initialCustomer = data[0]
      setSelectedCustomer(initialCustomer)
    }
    return response
  }

  const updateCustomers = async () => {
    try {
      const response = await fetchCustomers()
      if (response.status === 409) {
        try {
          //refetch customers on (first) 409 conflict return
          const response = await fetchCustomers()
          if (response.status === 409) {
            console.error('ERROR', response)
            errorFlag(i18n.t('Error fetching data'))
          }
        } catch (e) {
          console.error('ERROR', e)
          errorFlag(i18n.t('Error fetching data'))
        }
      }
    } catch (e) {
      console.error('ERROR', e)
      errorFlag(i18n.t('Error fetching data'))
    }
  }

  const changeSelectedCustomer = (customerId) => {
    const customer = customers.find((cus) => cus.id === customerId)
    setSelectedCustomer(customer)
  }

  const values = {
    customers,
    selectedCustomer,
    changeSelectedCustomer,
    sites,
    selectedSiteId,
    setSelectedSiteId,
    isFetchingData,
    refreshProfileData,
  }

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  return React.useContext(AppContext)
}
