import axios from 'axios'
import { useAppContext } from 'contextproviders/AppContext'
import { BACKEND_API_URL } from '../config'

// Create a custom hook to initialize the interceptor
export const useApiClientWithInterceptor = (uri) => {
  const { refreshProfileData } = useAppContext()

  const client = axios.create({
    baseURL: `${BACKEND_API_URL}/${uri}`,
    withCredentials: true,
  })
  client.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error.response && error.response.status === 409) {
        // Global 409 handling logic here
        refreshProfileData()
      }
      return Promise.reject(error) // Ensure the error is still propagated
    }
  )

  return client
}
